<template>
  <div class="sans-serif" id="conversion-container">
    <!-- TEXT -->
    <div class="center mr3">
      <h1 class="f2 f1-l fw2 white-90 mv0 dark-gray lh-title">Conversión</h1>
      <h1 class="fw1 f3 mt3 mb4 mid-gray">
        Introduzca el monto que le interesa obtener, seleccione una moneda y
        observe cuánto debe de llevar para las distintas entidades bancarias
      </h1>
    </div>
    <!-- INPUT CONTROLS -->
    <div class="cf ph3-ns">
      <div id="controls-container" class="fl w-100 ph4 mt5 mb2">
        <div class="center mw8">
          <input v-model="inputAmount" type="text" class="mr3 br2" />
          <select
            v-model="inputcurrency"
            name="currency-select"
            id="currency-select"
            class="ph2-ns br2"
          >
            <option v-for="c in currencies" :key="c.key" v-bind:value="c.key">
              {{ c.value }}
            </option>
          </select>
        </div>
      </div>
      <!-- CONVERSION TABLE -->
      <div class="fl w-100 ph4 mv4">
        <div class="">
          <table class="f6 w-100 mw8 center" cellspacing="0">
            <thead>
              <tr class="">
                <th class="fw6 bb b--black-20 tl pb3 pr3">Banco</th>
                <th
                  v-show="inputcurrency !== 'DOP'"
                  class="fw6 bb b--black-20 tl pb3 pr3"
                >
                  Pesos
                </th>
                <th
                  v-show="inputcurrency === 'DOP'"
                  class="fw6 bb b--black-20 tl pb3 pr3"
                >
                  Dólares
                </th>
                <th
                  v-show="inputcurrency === 'DOP'"
                  class="fw6 bb b--black-20 tl pb3 pr3"
                >
                  Euros
                </th>
              </tr>
            </thead>
            <tbody class="lh-copy">
              <tr class="" v-for="c in conversion" :key="c.bank">
                <td class="pv3 pr3 bb b--black-20">{{ c.bank }}</td>
                <td
                  v-show="inputcurrency !== 'DOP'"
                  class="pv3 pr3 bb b--black-20 tr measure mw3"
                >
                  {{ c.peso }}
                </td>
                <td
                  v-show="inputcurrency === 'DOP'"
                  class="pv3 pr3 bb b--black-20 tr measure mw3"
                >
                  {{ c.dollar }}
                </td>
                <td
                  v-show="inputcurrency === 'DOP'"
                  class="pv3 pr3 bb b--black-20 tr measure mw3"
                >
                  {{ c.euro }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecentExchange",
  props: ["exchanges", "usdFmt", "eurFmt", "dopFmt"],
  data() {
    return {
      inputAmount: 1,
      inputcurrency: "USD",
      currencies: [
        { key: "DOP", value: "Peso" },
        { key: "USD", value: "Dólar" },
        { key: "EUR", value: "Euro" },
      ],
    };
  },
  computed: {
    conversion() {
      const _this = this;
      let computedConversion = [];
      let fromPeso = this.inputcurrency === "DOP";

      // Apply division
      if (fromPeso) {
        computedConversion = this.exchanges.reduce((acc, exchange) => {
          const computed = {
            bank: exchange.bank.bank_name,
            euro:
              exchange.euro_buy === 0
                ? "-"
                : _this.eurFmt.format(this.inputAmount / exchange.euro_buy),
            dollar:
              exchange.dollar_buy === 0
                ? "-"
                : _this.usdFmt.format(this.inputAmount / exchange.dollar_buy),
          };
          return [...acc, computed];
        }, []);
      } else {
        let sellCurrencyKey = "";

        switch (this.inputcurrency) {
          case "USD":
            sellCurrencyKey = "dollar_sell";
            break;
          case "EUR":
            sellCurrencyKey = "euro_sell";
            break;
        }

        computedConversion = this.exchanges.reduce((acc, exchange) => {
          if (exchange[sellCurrencyKey] === 0) {
            return acc;
          }

          const computed = {
            bank: exchange.bank.bank_name,
            peso: _this.dopFmt.format(
              exchange[sellCurrencyKey] * this.inputAmount
            ),
          };
          return [...acc, computed];
        }, []);
      }

      return computedConversion;
    },
  },
};
</script>
