<template>
  <div class="pv8-m sans-serif" id="chart-container">
    <!-- TEXT -->
    <div class="center mr3">
      <h1 class="f2 f1-l fw2 white-90 mv0 dark-gray lh-title">Tasa del Día</h1>
      <h1 class="fw1 f3 mt3 mb4 gray">
        Tasa de cambio para en los principales bancos locales
      </h1>
    </div>
    <!-- TOOGLE SWITCH -->
    <div id="switch-container" class="fl w-100 mb2">
      <div class="center">
          <span class="mr3 fw3 near-black">{{
            exchangeState == "dollar" ? "Dólar" : "Euro"
          }}</span>
          <input
            type="checkbox"
            id="switch"
            class="checkbox br2"
            v-model="exchangeState"
            true-value="dollar"
            false-value="euro"
          />
          <label for="switch" class="toggle"> </label>
      </div>
    </div>
    <!-- CHARTS -->
    <div class="cf ph2-ns" v-show="exchangeState == 'dollar'">
      <div class="fl w-100 w-50-ns ph1 mt2 mb4">
        <canvas id="dollar-buy-chart"></canvas>
      </div>
      <div class="fl w-100 w-50-ns ph1 mt2 mb4">
        <canvas id="dollar-sell-chart"></canvas>
      </div>
    </div>
    <div class="cf ph2-ns" v-show="exchangeState == 'euro'">
      <div class="fl w-100 w-50-ns ph1 mt2 mb4">
        <canvas id="euro-buy-chart"></canvas>
      </div>
      <div class="fl w-100 w-50-ns ph1 mt2 mb4">
        <canvas id="euro-sell-chart"></canvas>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart, registerables } from "chart.js";
import {
  getRecentExchangeChartData,
  bindGradientBackground,
} from "./ExchangeChartData.js";

Chart.register(...registerables);

export default {
  name: "RecentExchangeChart",
  props: ["exchanges", "usdFmt", "eurFmt", "dopFmt"],
  data() {
    return {
      exchangeState: "dollar",
      chartsSet: { c1: null, c2: null, c3: null, c4: null },
    };
  },
  computed: {
    exchangeChartData() {
      const input = this.exchanges || [];
      return getRecentExchangeChartData(input);
    },
  },
  mounted() {
    this.initCanvas();
  },
  methods: {
    initCanvas: function () {
      do {
        // Don't do a thing untilt there's data
        if (this.exchangeChartData.length == 0) {
          continue;
        }

        // Dollar Charts
        const dollarBuyChart = document.getElementById("dollar-buy-chart");
        this.exchangeChartData[0] = bindGradientBackground(
          dollarBuyChart,
          this.exchangeChartData[0],
          true
        );

        const dollarSellChart = document.getElementById("dollar-sell-chart");
        this.exchangeChartData[1] = bindGradientBackground(
          dollarSellChart,
          this.exchangeChartData[1],
          true
        );

        // Euro Charts
        const euroBuyChart = document.getElementById("euro-buy-chart");
        this.exchangeChartData[2] = bindGradientBackground(
          euroBuyChart,
          this.exchangeChartData[2],
          false
        );

        const euroSellChart = document.getElementById("euro-sell-chart");
        this.exchangeChartData[3] = bindGradientBackground(
          euroSellChart,
          this.exchangeChartData[3],
          false
        );

        this.chartsSet.c1 = new Chart(
          dollarBuyChart,
          this.exchangeChartData[0]
        );
        this.chartsSet.c1.id = "chart-c1-dollar-buy";

        this.chartsSet.c2 = new Chart(
          dollarSellChart,
          this.exchangeChartData[1]
        );
        this.chartsSet.c2.id = "chart-c2-dollar-sell";

        this.chartsSet.c3 = new Chart(euroBuyChart, this.exchangeChartData[2]);
        this.chartsSet.c3.id = "chart-c3-euro-buy";

        this.chartsSet.c4 = new Chart(euroSellChart, this.exchangeChartData[3]);
        this.chartsSet.c4.id = "chart-c4-euro-sell";
      } while (this.exchangeChartData.length == 0);
    },
  },
};
</script>