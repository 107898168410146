export const getRecentExchangeChartData = (exchangeData, dopFmt) => {
    const chartConfigurations = [];
    const exchangeOperations = ["Dollar Buy", "Dollar Sell", "Euro Buy", "Euro Sell"];

    for (const e of exchangeOperations) {
        chartConfigurations.push(buildExchangesChartConfig(exchangeData, e, dopFmt));
    }

    return chartConfigurations;
};


export const bindGradientBackground = (el, dataset, isDollar = true) => {
    const gradientBackground = getGradientBackground(el, isDollar);
    dataset.data.datasets[0].backgroundColor = gradientBackground;

    return dataset;
}

const getGradientBackground = (el, isDollar = true) => {
    const ctx = el.getContext("2d");

    const gradientFill = ctx.createLinearGradient(0, 100, 0, 500);

    if (isDollar) {
        // Colors from https://colorswall.com/palette/2188/
        gradientFill.addColorStop(0, "rgba(42, 89, 63, 0.6)");
        gradientFill.addColorStop(0.5, "rgba(104, 158, 128, 0.6)");
        gradientFill.addColorStop(1, "rgba(216, 212, 180, 0.6)");
    } else {
        gradientFill.addColorStop(0, "rgba(93, 126, 167, 0.6)");
        gradientFill.addColorStop(1, "rgba(231, 228, 211, 0.6)");
    }

    return gradientFill;
}

const buildExchangesChartConfig = (exchangeData, exchangeOperation) => {
    // Base bar char configuration
    let chartConfiguration = {
        type: "bar",
        data: {},
        options: {
            responsive: true,
            plugins: { legend: { position: 'top' } },
            title: { display: true, text: exchangeOperation },
            scales: {
                y: {
                    min: 10,
                    max: 50,
                }
            }
        }
    };

    if (!exchangeData) {
        return chartConfiguration;
    }

    let dataBundle = {
        labels: [],
        datasets: [{
            label: exchangeOperation,
            data: []
        }]
    };

    // JSON parsed keys remains as snake case
    const exchangeOperationKey = exchangeOperation.toLowerCase().replace(' ', '_');
    const [min, max] = exchangeData.reduce((minMax, exchange, idx) => {
        // Skip empty entries
        if (exchange[exchangeOperationKey] === 0) {
            return minMax;
        }

        // Init the min and max
        if (idx === 0) {
            return [exchange[exchangeOperationKey], exchange[exchangeOperationKey]];
        }

        if (minMax[0] > exchange[exchangeOperationKey]) { minMax[0] = exchange[exchangeOperationKey]; }
        if (minMax[1] < exchange[exchangeOperationKey]) { minMax[1] = exchange[exchangeOperationKey]; }

        return minMax;
    }, [0, 0]);

    chartConfiguration.options.scales.y = { min: Math.floor(min * 0.98), max: Math.ceil(max * 1.01) };


    for (let e of exchangeData) {
        if (e[exchangeOperationKey] === 0) { continue; }

        dataBundle.labels = [...dataBundle.labels, e.bank.bank_name];
        dataBundle.datasets[0].data.push(e[exchangeOperationKey]);
    }

    chartConfiguration.data = dataBundle;

    return chartConfiguration;
}