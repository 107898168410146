<template>
  <div class="center">
    <p v-if="state == 'error'" class="orange">{{ error }}</p>
    <div v-else-if="state == 'ready'">
      <!-- NAV HEADER -->
      <!-- GRAPHS CONTAINER -->
      <div class="bg-ivory">
        <div class="w-90 center">
          <recent-exchange-chart
            :exchanges="recentExchange"
          ></recent-exchange-chart>
        </div>
      </div>
      <!-- CONVERSION CONTAINER -->
      <div class="bg-blue-gray">
        <div class="w-90 center">
          <recent-exchange 
          :exchanges="recentExchange"
          :usdFmt="usdFmt"
          :eurFmt="eurFmt"
          :dopFmt="dopFmt"
          > </recent-exchange>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecentExchange from "./components/ExchangeConversion.vue";
import RecentExchangeChart from "./components/RecentExchangeChart.vue";

const apiBase = process.env.VUE_APP_API_BASE;

export default {
  name: "App",
  components: {
    RecentExchange,
    RecentExchangeChart,
  },
  data() {
    return {
      recentExchange: [],
      error: "",
      state: "loading",
      usdFmt: new Intl.NumberFormat(navigator.language, {
        currency: "USD",
        style: "currency",
      }),
      eurFmt: new Intl.NumberFormat(navigator.language, {
        currency: "EUR",
        style: "currency",
      }),
      dopFmt: new Intl.NumberFormat(navigator.language, {
        currency: "DOP",
        style: "currency",
      }),
    };
  },
  created() {
    this.loadRecentExchange();
  },
  methods: {
    async loadRecentExchange() {
      try {
        const recentExchange = await fetch(`${apiBase}/api/exchange/recent`);
        this.recentExchange = await recentExchange.json();
        this.state = "ready";
      } catch (err) {
        this.err = err;
        this.state = "error";
      }
    },
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
